import moment from "moment";
import objectHash from "object-hash";
import { defineStore } from "pinia";

import { getPlanID } from "@/router/services.js";
import { filter } from "@/services/object.js";
import type {
  GetVerfahrenProtokolleStatus,
  GetVerfahrenProtokolleTyp,
  PageProtokollRest,
  TerminDetailRest,
  VerfahrenDetailRest,
  VerfahrenEinfacheSucheRest,
  VerfahrenNeuRest,
  VerfahrenUebersichtRest,
} from "@/services/open-api";
import { VerfahrensteilschrittRest } from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";
import { useFundamentalStore } from "@/stores/fundamental.ts";

export interface SearchResult extends VerfahrenEinfacheSucheRest {
  isSimulated?: boolean;
}

export interface detailedSearchResult extends VerfahrenUebersichtRest {
  isSimulated?: boolean;
}

export interface ProceedingContainerItem {
  backup?: VerfahrenDetailRest;
  backupChecksum?: string;
  isStub?: boolean;
  subscribed: boolean;
  workingCopy?: VerfahrenDetailRest | VerfahrenUebersichtRest;
  loading?: boolean;
  parallelverfahren?: VerfahrenDetailRest[];
  loadingPV?: boolean;
  isInitialLoad?: boolean;
}

export interface ProceedingsStoreState {
  proceedingContainer: {
    [key: string]: ProceedingContainerItem;
  };
  subscribedProceedingIDs: string[];
  searchResults: SearchResult[];
  protocolsData: {
    planID: string;
    protocolsContent: PageProtokollRest;
  }[];
  errors: Set<string>;
  plannameChangeData: {
    isPlannameValid: boolean;
    errorMessage: string;
    isModalToBeShownAfterUnpublish: boolean;
  };
  selectedProceedingForNameChange: VerfahrenDetailRest;
}

export const useProceedingsStore = defineStore("proceedings", {
  state: (): ProceedingsStoreState => ({
    proceedingContainer: {},
    subscribedProceedingIDs: [],
    searchResults: [],
    protocolsData: [],
    errors: new Set(),
    plannameChangeData: {
      isPlannameValid: true,
      errorMessage: "",
      isModalToBeShownAfterUnpublish: false,
    },

    selectedProceedingForNameChange: {},
  }),
  actions: {
    /**
     * Performs a search of proceedings matching given criteria.
     * @param searchOptions Criteria defined for the search.
     */
    searchProceedings(searchOptions: object): Promise<VerfahrenEinfacheSucheRest[]> {
      const appStore = useAppStore();
      const optionsBlueprint = {
        name: undefined,
        plannameArbeitstitel: undefined,
        besitzer: undefined,
        codePlanstatus: undefined,
        codeZustaendigkeit: undefined,
        codeBezirk: undefined,
        codeGebietseinheit: undefined,
        codePlanart: undefined,
        codeVerfahrenssteuerung: undefined,
        codeVerfahrensstand: undefined,
        options: undefined,
      };
      const definedOptions = { ...optionsBlueprint, ...searchOptions };
      const {
        name,
        plannameArbeitstitel,
        besitzer,
        codePlanstatus,
        codeZustaendigkeit,
        codeBezirk,
        codeGebietseinheit,
        codePlanart,
        codeVerfahrenssteuerung,
        codeVerfahrensstand,
        options,
      } = definedOptions;

      appStore.showPageLoadingIndicator({
        id: "searchProceedings",
        text: "Einen Moment bitte, die Suche wird ausgeführt.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .einfacheSucheVerfahren(
            name,
            plannameArbeitstitel,
            besitzer,
            codePlanstatus,
            codeZustaendigkeit,
            codeBezirk,
            codeGebietseinheit,
            codePlanart,
            codeVerfahrenssteuerung,
            codeVerfahrensstand,
            options,
          )
          .then((response) => {
            this.searchResults = response.data;

            appStore.hidePageLoadingIndicator("searchProceedings");

            resolve(response.data);
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("searchProceedings");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Suche nach Verfahren ist fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads all proceedings the current user is subscribed to and caches the data.
     */
    loadSubscribedProceedings() {
      const appStore = useAppStore();

      if (!appStore.resolved["/verfahren"]) {
        appStore.showPageLoadingIndicator({
          id: "loadSubscribedProceedings",
          text: "Moment. Ihre Verfahren werden geladen.",
        });

        openAPIFactory
          .verfahrenResourceApiFactory()
          .getAllVerfahren()
          .then((response) => {
            appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

            if (response.data.length) {
              response.data.forEach((proceeding) => {
                if (proceeding.planstatus?.code !== "0110") {
                  this.cacheProceeding({
                    proceeding,
                    isStub: true,
                    subscribed: true,
                  });
                }
              });
            }

            appStore.resolved["/verfahren"] = true;
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Loads all proceeding IDs the current user is subscribed to and caches the data.
     */
    loadSubscribedProceedingIDs() {
      const appStore = useAppStore();

      if (!appStore.resolved["/nutzer/verfahren"]) {
        appStore.showPageLoadingIndicator({
          id: "bootstrap",
          text: "Moment. Die Anwendung wird geladen.",
        });

        openAPIFactory
          .nutzerResourceApiFactory()
          .getNutzerVerfahren()
          .then((response) => {
            if (response.data) {
              this.subscribedProceedingIDs = response.data
                .filter((entry) => typeof entry.planID === "string")
                .map((entry) => entry.planID) as string[];

              appStore.resolved["/nutzer/verfahren"] = true;
            }
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
            });
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("bootstrap");
          });
      }
    },
    /**
     * Loads a specific proceeding and caches the data.
     * If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     * within an object, a forced reload is done.
     */
    loadProceedingByID(
      proceedingID?: string | undefined,
      forceReload?: boolean,
    ): Promise<false | VerfahrenDetailRest> {
      const appStore = useAppStore();

      const planID = proceedingID ?? getPlanID();

      if (!planID) {
        return new Promise((reject) => {
          appStore.showErrorModal({
            response: { data: { message: "Die ID des Verfahrens konnte nicht ermittelt werden." } },
            customErrorMessage: "Laden des Verfahrens fehlgeschlagen!",
          });
          reject(false);
        });
      }

      const proceedingItem = this.proceedingContainer[planID] ?? {
        isStub: true,
        subscribed: false,
      };

      if (!proceedingItem.loading && (proceedingItem.isStub || forceReload)) {
        appStore.showPageLoadingIndicator({
          id: "loadProceeding",
          text: "Einen Moment bitte, das gewünschte Verfahren wird geladen.",
        });

        this.proceedingContainer[planID] = {
          ...proceedingItem,
          loading: true,
          subscribed: proceedingItem.subscribed,
        };

        return new Promise((resolve, reject) => {
          openAPIFactory
            .verfahrenResourceApiFactory()
            .getVerfahrenDetail(planID as string)
            .then((response) => {
              this.cacheProceeding({
                proceeding: response.data,
                isStub: false,
                subscribed: this.checkIfProceedingIsSubscribed(planID),
                isInitialLoad: proceedingItem.isStub ?? false,
              });

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden des Verfahrens fehlgeschlagen!",
              });

              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("loadProceeding");
            });
        });
      }

      this.proceedingContainer[planID].isInitialLoad = false;

      return new Promise((resolve) => {
        resolve(false);
      });
    },
    /**
     * Loads all parallel proceedings of a specific proceeding.
     * @param proceedingID ID of the plan to load all parallel proceedings currently given as object, later given as String (see comment below).
     */
    loadParallelProceedingsForID(proceedingID?: string): Promise<VerfahrenDetailRest[] | false> {
      const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();
      const appStore = useAppStore();
      const promiseList: Promise<VerfahrenDetailRest>[] = [];

      if (planID) {
        if (
          typeof this.proceedingContainer[planID] !== "undefined" ||
          this.proceedingContainer[planID].loading !== undefined
        ) {
          const parallelProceedings = this.getParallelProceedingsByProceedingID(planID);
          const initialParallelProceedingIds: string[] =
            this.proceedingContainer[planID].workingCopy?.parallelverfahrenPlanIDs || [];

          if (
            parallelProceedings.length === initialParallelProceedingIds.length &&
            parallelProceedings.every(
              (proceeding: VerfahrenDetailRest) =>
                proceeding.planID && initialParallelProceedingIds.includes(proceeding.planID),
            )
          ) {
            return new Promise((resolve) => {
              resolve(parallelProceedings);
            });
          }

          appStore.showPageLoadingIndicator({
            id: "loadParallelProceeding",
            text: "Einen Moment bitte, die zugehörigen Parallelverfahren werden geladen.",
          });

          initialParallelProceedingIds.forEach((pvID: string) => {
            promiseList.push(
              new Promise((resolve, reject) => {
                this.proceedingContainer[planID].loadingPV = true;

                openAPIFactory
                  .verfahrenResourceApiFactory()
                  .getVerfahrenDetail(pvID)
                  .then((response) => {
                    resolve(response.data);
                  })
                  .catch((error) => {
                    reject(error);
                  })
                  .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
              }),
            );
          });
        }

        return Promise.all(promiseList)
          .then((response) => {
            // here we DO NOT want to update the complete object as done in "cacheProceeding"
            // otherwise the GraphMilestoneGraphic Overview will be completely rerendered for each toggle of parallel proceedings badge
            if (this.proceedingContainer[planID].loadingPV) {
              this.proceedingContainer[planID].parallelverfahren = response;
              this.proceedingContainer[planID].loadingPV = undefined;
            }

            return this.getParallelProceedingsByProceedingID(planID);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Parallelverfahren fehlgeschlagen!",
            });

            return [];
          })
          .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
      }

      return new Promise((resolve) => {
        resolve(false);
      });
    },
    /**
     * Creates a new proceeding.
     * @param payload
     * @param payload.newProceeding The data object defining the new proceeding.
     * @param payload.file The GML document.
     */
    createProceeding(payload: {
      newProceeding: VerfahrenNeuRest;
      file: File;
    }): Promise<false | string> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "createProceeding",
        text: "Moment. Ihr Verfahren wird angelegt.",
      });

      return new Promise((resolve) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .createVerfahren1(payload.newProceeding, payload.file)
          .then((response) => {
            const proceeding = response.data;

            if (proceeding.planID) {
              this.cacheProceeding({
                proceeding: proceeding,
                isStub: false,
                subscribed: true,
              });

              this.subscribedProceedingIDs = [...this.subscribedProceedingIDs, proceeding.planID];

              appStore.resolved["/nutzer/verfahren"] = true;

              resolve(proceeding.planID);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Erstellen des Verfahrens fehlgeschlagen!",
            });
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("createProceeding");
          });
      });
    },
    /**
     * Deletes a simulated proceeding with the specified plan ID.
     * @param planID The ID of the proceeding to be deleted.
     * @returns A promise that resolves with the response data upon successful deletion.
     */
    deleteSimulatedProceeding(planID: string): Promise<string> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "deleteSimulatedProceeding",
        text: "Moment. Ihr Planverfahren wird gelöscht.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .deleteVerfahren(planID)
          .then((response) => {
            if (this.subscribedProceedingIDs.includes(planID)) {
              this.proceedingContainer = filter(
                this.proceedingContainer,
                (element: ProceedingContainerItem, key: string) => key !== planID,
              ) as {
                [key: string]: ProceedingContainerItem;
              };

              this.subscribedProceedingIDs = this.subscribedProceedingIDs.filter(
                (element) => element !== planID,
              );
            }
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Löschen des simulierten Planverfahrens ist fehlgeschlagen!",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("deleteSimulatedProceeding");
          });
      });
    },
    /**
     * Updates a specific proceeding.
     * @param payload
     * @param payload.proceedingData The proceeding data that should get saved.
     * @param payload.proceedingID The ID of the proceeding.
     * @returns Returns a promise that gets resolved or rejected based upon the response of the backend.
     */
    saveProceedingByID(payload: {
      proceedingData: object;
      proceedingID: string;
    }): Promise<VerfahrenDetailRest> {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .updateVerfahren(payload.proceedingData, payload.proceedingID)
          .then((response) => {
            this.cacheProceeding({
              proceeding: response.data,
              isStub: false,
              subscribed: this.checkIfProceedingIsSubscribed(payload.proceedingID),
              forceCache: true,
            });

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Speichern der Änderungen ist fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads the protocols of the proceeding.
     * @param payload
     * @param payload.filterParams The filter params.
     * @param payload.pageable Pagination options.
     */
    loadProceedingProtocols(payload: {
      filterParams: {
        Status: GetVerfahrenProtokolleStatus[];
        Typ: GetVerfahrenProtokolleTyp[];
        Beschreibung: string[];
        Datum: string[];
        "Bearbeitet von": string[];
      };
      pageable: {
        page: number;
        size: number;
        sort: string[];
      };
    }): Promise<PageProtokollRest | false> {
      const planID = getPlanID();

      if (planID) {
        return new Promise((resolve, reject) => {
          const status = payload.filterParams.Status.length
            ? payload.filterParams.Status
            : undefined;
          const typ = payload.filterParams.Typ.length ? payload.filterParams.Typ : undefined;
          const beschreibung = payload.filterParams.Beschreibung.length
            ? payload.filterParams.Beschreibung[0]
            : undefined;
          const erfassungsdatum = payload.filterParams.Datum.length
            ? moment(payload.filterParams.Datum[0], "DD.MM.YYYY").format("YYYY-MM-DD")
            : undefined;
          const erfasser = payload.filterParams["Bearbeitet von"].length
            ? payload.filterParams["Bearbeitet von"][0]
            : undefined;

          openAPIFactory
            .verfahrenResourceApiFactory()
            .getVerfahrenProtokolle(
              planID,
              status,
              typ,
              beschreibung,
              erfasser,
              erfassungsdatum,
              payload.pageable.page,
              payload.pageable.size,
              payload.pageable.sort,
            )
            .then((response) => {
              const protocolsContent = response.data;
              const protocolsData = [...this.protocolsData];
              const index = protocolsData.findIndex((protocol) => protocol.planID === planID);

              if (index !== -1) {
                protocolsData[index] = { planID, protocolsContent };
              } else {
                protocolsData.push({
                  planID,
                  protocolsContent,
                });
              }

              this.protocolsData = protocolsData;

              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      return new Promise((resolve) => {
        resolve(false);
      });
    },
    /**
     * Resets the working copy of a proceeding to its unaltered state.
     * @param proceedingID The ID of the proceeding.
     */
    resetProceedingWorkingCopy(proceedingID: string) {
      const proceedingData = { ...this.proceedingContainer };

      proceedingData[proceedingID].workingCopy = JSON.parse(
        JSON.stringify(this.proceedingContainer[proceedingID].backup),
      );

      this.proceedingContainer = proceedingData;
    },
    /**
     * Caches a proceeding object.
     * @param payload
     * @param payload.proceeding The proceedings data object.
     * @param payload.isStub Flag determining if the proceeding object is a fully loaded one.
     * @param payload.subscribed Flag determining if the current user is subscribed to this proceeding.
     * @param payload.isInitialLoad Flag determining if it is the first time the proceeding gets loaded.
     * @param payload.forceCache Force a caching (true for cache after save).
     */
    cacheProceeding(payload: {
      proceeding: VerfahrenDetailRest | VerfahrenUebersichtRest;
      isStub: boolean;
      subscribed: boolean;
      isInitialLoad?: boolean;
      forceCache?: boolean;
    }) {
      if (payload.proceeding.planID) {
        const proceedingData = { ...this.proceedingContainer };

        if (
          typeof proceedingData[payload.proceeding.planID] === "undefined" ||
          proceedingData[payload.proceeding.planID].loading ||
          proceedingData[payload.proceeding.planID].isStub ||
          payload.forceCache
        ) {
          proceedingData[payload.proceeding.planID] = {
            workingCopy: payload.proceeding,
            isStub: payload.isStub,
            subscribed: payload.subscribed,
            backup: JSON.parse(JSON.stringify(payload.proceeding)),
            backupChecksum: objectHash.sha1(payload.proceeding),
            parallelverfahren: [],
            isInitialLoad: payload.isInitialLoad,
          };
        } else {
          proceedingData[payload.proceeding.planID].subscribed = payload.subscribed;
        }
        this.proceedingContainer = proceedingData;
      }
    },
    /**
     * Alters the subscription flag of a specific proceeding and caches the proceedings object if necessary.
     * @param payload
     * @param payload.action The type of the alteration.
     * @param payload.proceeding The proceedings data object.
     * @param payload.proceedingID The proceeding ID.
     * @param payload.subscribedProceedingIDs The resulting list of subscribed proceeding IDs.
     */
    alterSubscriptions(payload: {
      action: string;
      proceeding: VerfahrenDetailRest;
      proceedingID: string;
      subscribedProceedingIDs: string[];
    }) {
      const appStore = useAppStore();

      // eslint-disable-next-line default-case
      switch (payload.action) {
        case "add":
          if (payload.proceeding.planID) {
            if (typeof this.proceedingContainer[payload.proceeding.planID] === "undefined") {
              this.cacheProceeding({
                proceeding: payload.proceeding,
                isStub: true,
                subscribed: true,
              });
            } else {
              this.proceedingContainer[payload.proceeding.planID].subscribed = true;
            }
          }
          break;

        case "remove":
          if (typeof this.proceedingContainer[payload.proceedingID] !== "undefined") {
            this.proceedingContainer[payload.proceedingID].subscribed = false;
          }
          break;
      }

      this.subscribedProceedingIDs = payload.subscribedProceedingIDs;

      appStore.resolved["/nutzer/verfahren"] = true;
    },
    /**
     * Adds or removes an error from the errors set.
     * @param payload Object containing error info
     */
    handleErrors(payload: { hasError: boolean; id: string }) {
      // if error exists add it to the set otherwise delete it from the set
      if (payload.hasError) {
        this.errors.add(payload.id);
      } else {
        this.errors.delete(payload.id);
      }
    },
    /**
     * Resets all errors of the errors set.
     */
    resetErrors() {
      this.errors.clear();
    },
    /**
     * set the Data selected for planname Change
     */
    setProceedingsDataSelectedForPlannameChange(selectedProceedingsData: VerfahrenDetailRest) {
      this.selectedProceedingForNameChange = { ...selectedProceedingsData };
    },
    /**
     * set the Error State for planname Change
     */
    setErrorStateForPlannameChange(isPlannameValid: boolean, errorMessage: string) {
      this.plannameChangeData.isPlannameValid = isPlannameValid;
      this.plannameChangeData.errorMessage = errorMessage;
    },
    /**
     * set a flag if process is after unpublish
     */
    setFlagForPlannameChangeAfterUnpublish(modalAfterUnpublish: boolean) {
      this.plannameChangeData.isModalToBeShownAfterUnpublish = modalAfterUnpublish;
    },
    resetDataSelectedForPlannameChange() {
      this.selectedProceedingForNameChange = {};
    },
    resetPlannameChangeData() {
      this.plannameChangeData = {
        isPlannameValid: true,
        errorMessage: "",
        isModalToBeShownAfterUnpublish: false,
      };
    },
    setProceedingIsInitialLoad(proceedingID: string, isInitialLoad: boolean) {
      if (this.proceedingContainer[proceedingID]) {
        this.proceedingContainer[proceedingID].isInitialLoad = isInitialLoad;
      }
    },
  },
  getters: {
    /**
     * Returns an array of all subscribed proceedings, ordered by the user's preference.
     * @returns All subscribed proceedings in the desired order.
     */
    subscribedProceedings(): (VerfahrenDetailRest | VerfahrenUebersichtRest)[] {
      const fundamentalStore = useFundamentalStore();
      const sortingOrder = fundamentalStore.userSettings.cockpitSettings.sortingOrder;
      const ordered: (VerfahrenDetailRest | VerfahrenUebersichtRest)[] = [];
      let proceedings: (VerfahrenDetailRest | VerfahrenUebersichtRest)[] = [];

      Object.values(this.proceedingContainer).forEach((proceeding: ProceedingContainerItem) => {
        if (proceeding.workingCopy && proceeding.subscribed && !proceeding.loading) {
          proceedings.push(proceeding.workingCopy);
        }
      });

      // Make sure the stores is write-protected from changes to this array
      proceedings = [...proceedings];

      proceedings.sort((a, b) => {
        const aName = a.planname ? a.planname : a.arbeitstitel ? a.arbeitstitel : "";
        const bName = b.planname ? b.planname : b.arbeitstitel ? b.arbeitstitel : "";

        return aName.localeCompare(bName);
      });

      switch (sortingOrder) {
        case "AKTIV_SIMULIERT":
        case "SIMULIERT_AKTIV": {
          // Aktive Verfahren oben, simulierte unten
          // Simulierte Verfahren oben, aktive unten

          const simulated: (VerfahrenDetailRest | VerfahrenUebersichtRest)[] = [];
          const active: (VerfahrenDetailRest | VerfahrenUebersichtRest)[] = [];

          proceedings.forEach((proceeding) => {
            if (this.checkIfProceedingIsSimulated(proceeding)) {
              simulated.push(proceeding);
            } else {
              active.push(proceeding);
            }
          });

          return sortingOrder === "AKTIV_SIMULIERT"
            ? [...active, ...simulated]
            : [...simulated, ...active];
        }
        case "START_DATUM": {
          // Datum der Grobabstimmung

          const hasVTS = (proceeding: VerfahrenDetailRest | VerfahrenUebersichtRest): boolean =>
            Object.prototype.hasOwnProperty.call(proceeding, "verfahrensteilschritte");

          const getVTS = (
            proceeding: VerfahrenDetailRest | VerfahrenUebersichtRest,
          ): TerminDetailRest | undefined => {
            const vts: VerfahrensteilschrittRest | undefined = (
              proceeding.verfahrensteilschritte as VerfahrensteilschrittRest[]
            )?.find((vts) => vts.codeVerfahrensschritt?.code === "2000");

            return vts && vts.termine
              ? vts.termine.find((termin) => termin.beschreibung === "Grobabstimmung")
              : undefined;
          };

          const getDate = (termin?: TerminDetailRest) =>
            termin?.geplanterOderBerechneterZeitraum?.ende
              ? new Date(termin.geplanterOderBerechneterZeitraum.ende).getTime()
              : -1;

          proceedings.sort((a, b) => {
            const aVTS = hasVTS(a) ? getVTS(a) : undefined;
            const bVTS = hasVTS(b) ? getVTS(b) : undefined;

            const aDate = getDate(aVTS);
            const bDate = getDate(bVTS);

            return aDate > bDate ? 1 : -1;
          });

          return proceedings;
        }
        case "CUSTOM": {
          const proceedingOrder =
            fundamentalStore.userSettings.cockpitSettings.customProceedingOrder;

          proceedingOrder.forEach((planID) => {
            const index = proceedings.findIndex((proceeding) => proceeding.planID === planID);

            if (index > -1) {
              const add = proceedings.splice(index, 1).shift();

              if (add) {
                ordered.push(add);
              }
            }
          });

          return [...ordered, ...proceedings];
        }
        case "VERFAHRENSSTAND": {
          // Verfahrensstand

          const statusOrder = [
            "2000", //imVerfahren
            "1000", //simuliert
            "3000", //festgestellt
            "6000", //laufendesNormenkontrollverfahren
            "4000", //ganzAufgehoben
            "5000", //eingestellt
          ];

          statusOrder.forEach((status) => {
            ordered.push(
              ...proceedings.filter((proceeding) => {
                const verfahrensstand = proceeding.verfahrensstand
                  ? proceeding.verfahrensstand.code
                  : "";

                return verfahrensstand === status;
              }),
            );
          });

          ordered.push(
            ...proceedings.filter((proceeding) => {
              return proceeding.verfahrensstand === null;
            }),
          );

          return ordered;
        }
        case "PLANNAME": // Name des Verfahrens
        default:
          return proceedings;
      }
    },
    /**
     * Checks if a proceeding is readonly.
     * @returns The result of the check.
     */
    checkIfProceedingIsReadonly(): (proceedingID?: string) => boolean {
      return (proceedingID) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID) {
          const proceeding = this.getProceedingWorkingCopy(planID);

          if (typeof proceeding === "object" && Object.keys(proceeding).length) {
            return !this.checkIfProceedingIsSubscribed(planID);
          }
        }

        return true;
      };
    },
    /**
     * Checks if a proceeding is simulated.
     * @returns The result of the check.
     */
    checkIfProceedingIsSimulated(): (
      proceedingData?: VerfahrenDetailRest | VerfahrenUebersichtRest,
    ) => boolean {
      return (proceedingData) => {
        let proceeding;

        if (proceedingData === undefined || proceedingData === null) {
          proceeding = this.getProceedingWorkingCopy();
        } else {
          switch (proceedingData.constructor.name) {
            case "String":
              proceeding = this.getProceedingWorkingCopy(proceedingData.planID);
              break;
            default:
              proceeding = proceedingData;
          }
        }

        return proceeding?.planstatus?.code === "0300";
      };
    },
    /**
     * Checks if a proceeding is subscribed.
     * @returns The result of the check.
     */
    checkIfProceedingIsSubscribed(): (proceedingID?: string) => boolean {
      return (proceedingID) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID) {
          return this.subscribedProceedingIDs.indexOf(planID) > -1;
        }

        return false;
      };
    },
    /**
     * Returns a working copy of a specific proceeding.
     * @returns The working copy of the proceeding.
     */
    getProceedingWorkingCopy(): (
      proceedingID?: string,
      writeable?: boolean,
    ) => VerfahrenDetailRest | VerfahrenUebersichtRest | undefined {
      return (proceedingID, writeable) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID !== undefined && typeof this.proceedingContainer[planID] !== "undefined") {
          return writeable
            ? this.proceedingContainer[planID].workingCopy
            : { ...this.proceedingContainer[planID].workingCopy };
        }

        return undefined;
      };
    },
    /**
     * Returns the checksum of a proceeding working copy.
     * @returns The SHA1 checksum of a proceeding working copy.
     */
    getWorkingCopyChecksum(): (proceedingID?: string) => string {
      return (proceedingID) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID) {
          const workingCopy = this.getProceedingWorkingCopy(planID);

          return objectHash.sha1(workingCopy || {});
        }

        return "";
      };
    },
    /**
     * Checks if the state of a proceeding working copy has been altered.
     * @returns The result of the check.
     */
    checkIfProceedingWorkingCopyHasBeenAltered(): (proceedingID?: string) => boolean {
      return (proceedingID) => {
        const planID = typeof proceedingID === "string" ? proceedingID : getPlanID();

        if (planID) {
          if (
            !this.proceedingContainer[planID] ||
            (typeof this.proceedingContainer[planID] !== "undefined" &&
              this.proceedingContainer[planID].loading !== undefined)
          ) {
            return false;
          }

          return (
            this.getWorkingCopyChecksum(planID) !== this.proceedingContainer[planID].backupChecksum
          );
        }

        return false;
      };
    },
    /**
     * Returns a function retrieving the parallel proceedings of a proceeding defined by its ID.
     * @returns An array of parallel proceedings.
     */
    getParallelProceedingsByProceedingID(): (proceedingID: string) => VerfahrenDetailRest[] {
      return (proceedingID) => {
        if (
          typeof this.proceedingContainer[proceedingID] !== "undefined" &&
          this.proceedingContainer[proceedingID].loading === undefined &&
          this.proceedingContainer[proceedingID].loadingPV === undefined
        ) {
          return this.proceedingContainer[proceedingID].parallelverfahren || [];
        }

        return [];
      };
    },
    /**
     * Returns a function that determines if a given proceeding is predictable.
     */
    proceedingIsPredictableByID(): (planID: string) => boolean {
      return (planID) => {
        const workingCopy = this.proceedingContainer[planID]?.workingCopy as VerfahrenDetailRest;

        return workingCopy?.prognostizierbar === true;
      };
    },
    /**
     * Returns a function that checks whether 'hatZeitplanung' is true for a given proceeding.
     */
    proceedingHasScheduleByID(): (planID: string) => boolean {
      return (planID) => {
        return this.proceedingContainer[planID]?.workingCopy?.hatZeitplanung === true;
      };
    },
    /**
     * Returns a function that checks whether 'verfahrensstand' has changed for a given proceeding.
     */
    hasProceedingStatusChanged(): (planID: string) => boolean {
      return (planID) => {
        const proceeding = this.proceedingContainer[planID];

        return (
          proceeding.workingCopy?.verfahrensstand?.code !== proceeding.backup?.verfahrensstand?.code
        );
      };
    },
    /**
     * Returns a function that returns isInitialLoad for a given proceeding.
     */
    isProceedingInitialLoad(): (planID: string) => boolean {
      return (planID) => {
        return this.proceedingContainer[planID]?.isInitialLoad ?? false;
      };
    },
  },
});
