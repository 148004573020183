/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.133.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { TemplateDetailRest } from '../models';
// @ts-ignore
import { TemplateEditRest } from '../models';
// @ts-ignore
import { TemplateUebersichtRest } from '../models';
/**
 * TemplateResourceApi - axios parameter creator
 * @export
 */
export const TemplateResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Templates aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Templates aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert ein Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert ein Template aus.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getTemplate', 'name', name)
            const localVarPath = `/konfigurationen/templates/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Datei zu einem Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Datei zu einem Template aus.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDatei: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getTemplateDatei', 'name', name)
            const localVarPath = `/konfigurationen/templates/{name}/datei`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template.
         * @param {TemplateEditRest} templateEditRest die geaenderten Templatedaten.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (templateEditRest: TemplateEditRest, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateEditRest' is not null or undefined
            assertParamExists('updateTemplate', 'templateEditRest', templateEditRest)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateTemplate', 'name', name)
            const localVarPath = `/konfigurationen/templates/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template via upload einer XML-Datei.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template via upload einer XML-Datei.
         * @param {File} file 
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateByFile: async (file: File, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateTemplateByFile', 'file', file)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateTemplateByFile', 'name', name)
            const localVarPath = `/konfigurationen/templates/{name}/datei`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateResourceApi - functional programming interface
 * @export
 */
export const TemplateResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Templates aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Templates aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateUebersichtRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTemplates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateResourceApi.getAllTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert ein Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert ein Template aus.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateResourceApi.getTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Datei zu einem Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Datei zu einem Template aus.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateDatei(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateDatei(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateResourceApi.getTemplateDatei']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template.
         * @param {TemplateEditRest} templateEditRest die geaenderten Templatedaten.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(templateEditRest: TemplateEditRest, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(templateEditRest, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateResourceApi.updateTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template via upload einer XML-Datei.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template via upload einer XML-Datei.
         * @param {File} file 
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateByFile(file: File, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplateByFile(file, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TemplateResourceApi.updateTemplateByFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TemplateResourceApi - factory interface
 * @export
 */
export const TemplateResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Templates aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Templates aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTemplates(options?: any): AxiosPromise<Array<TemplateUebersichtRest>> {
            return localVarFp.getAllTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert ein Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert ein Template aus.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(name: string, options?: any): AxiosPromise<TemplateDetailRest> {
            return localVarFp.getTemplate(name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Datei zu einem Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert die Datei zu einem Template aus.
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDatei(name: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getTemplateDatei(name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template.
         * @param {TemplateEditRest} templateEditRest die geaenderten Templatedaten.
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(templateEditRest: TemplateEditRest, name: string, options?: any): AxiosPromise<TemplateDetailRest> {
            return localVarFp.updateTemplate(templateEditRest, name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Template via upload einer XML-Datei.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert ein Template via upload einer XML-Datei.
         * @param {File} file 
         * @param {string} name Name des Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateByFile(file: File, name: string, options?: any): AxiosPromise<TemplateDetailRest> {
            return localVarFp.updateTemplateByFile(file, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateResourceApi - object-oriented interface
 * @export
 * @class TemplateResourceApi
 * @extends {BaseAPI}
 */
export class TemplateResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Liefert alle Templates aus.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert alle Templates aus.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getAllTemplates(options?: RawAxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getAllTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert ein Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert ein Template aus.
     * @param {string} name Name des Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplate(name: string, options?: RawAxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplate(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Datei zu einem Template aus.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert die Datei zu einem Template aus.
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public getTemplateDatei(name: string, options?: RawAxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).getTemplateDatei(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert ein Template.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Aktualisiert ein Template.
     * @param {TemplateEditRest} templateEditRest die geaenderten Templatedaten.
     * @param {string} name Name des Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public updateTemplate(templateEditRest: TemplateEditRest, name: string, options?: RawAxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).updateTemplate(templateEditRest, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert ein Template via upload einer XML-Datei.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Aktualisiert ein Template via upload einer XML-Datei.
     * @param {File} file 
     * @param {string} name Name des Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateResourceApi
     */
    public updateTemplateByFile(file: File, name: string, options?: RawAxiosRequestConfig) {
        return TemplateResourceApiFp(this.configuration).updateTemplateByFile(file, name, options).then((request) => request(this.axios, this.basePath));
    }
}

